import { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { styled } from "styled-components";
import pic from "../assets/images/main_img.png";
import PopupImg from "../assets/images/pop-240924.jpg";
import Modal from "../components/ModalPopup";

const Pic = styled.img`
  max-width: 90%;
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
`;

const PicPop = styled.img`
  max-width: 100%;
  width: 600px;
`;
function Main() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <HelmetProvider>
      <div style={{ textAlign: "center", padding: "0.5rem 0 0 0" }}>
        <Helmet>
          <title>제8회 울산산업골든벨</title>
        </Helmet>
        <p
          style={{
            textAlign: "left",
            color: "whitesmoke",
            fontFamily: "Pretendard-thin",
            marginBottom: "2rem",
          }}
        ></p>
        <Pic src={pic} alt="울산산업골든벨" />
      </div>

      <Modal isOpen={isModalOpen} onClose={toggleModal}>
        <>
          <PicPop src={PopupImg} alt="popup" />
        </>
      </Modal>
    </HelmetProvider>
  );
}

export default Main;
